﻿import { BaseTextContent } from '../BaseTextContent'

export const translations: BaseTextContent = {
    LinkButtonText: 'Enlace con mi paquete contable',
    SecurelySubmitPrompt: 'Presentar con seguridad datos financieros',
    QBDesktop: 'Escritorio',
    QBOnline: 'En linea',
    Xero: 'Xero',
    SageIntacct: 'Sage Intacct',
    ExampleAccountingSystem: 'Ejemplo',
    ClickYourAccountingPackage: 'Haz clic en tu paquete contable(Accounting Package) de arriba para autorizar una conexión segura de los datos financieros para completar su aplicación',
    PNLStatements: 'Declaraciones de pérdida y ganancias',
    BalanceSheets: 'Balances',
    TransactionData: 'Datos de transacción',
    NoteNoPersonalInformation: 'Nota: Strongbox NO recoge información personal, como Números de Seguro Social',
    EstablishingSecureConnection: 'Estableciendo conexión segura',
    GatheringRequiredInformation: 'Reuniendo información requerida',
    DeliveringToYourAccountMgr: 'Entregando a su gerente de cuenta',
    YourDataIsSubmitted: 'Sus datos financieros han sido presentados!',
    Done: 'Hecho',
    ConnectToAccountingPkg: 'Conectar a',
    DisconnectFromAccountingPkg: 'Desconectar',
    UnknownAccountingPkg: 'Paquete de contabilidad desconocido',
    StartFinancialsCreateRequestDetailedError: 'No se pudo crear su solicitud para ${accountingPackage}, estado de respuesta: ${responseStatus}',
    StartFinancialsCreateRequestSummaryError: 'Se produjo un error comunicándose con el servidor, por favor intentar otra vez más tarde.',
    StartFinancialsCreateWindowDetailedError: 'Falló la creación de la ventana para conectar con el sistema contable del cliente ${accountingPackage}',
    StartFinancialsCreateWindowSummaryError: 'Un error ocurrió localmente. Intenta cerrar la ventana del navegador y intenta mas tarde.',
    StartFinancialsImportDetailedError: 'Falló la creación de un trabajo al que importar registros financieros para ${accountingPackage}',
    StartFinancialsImportSummaryError: 'Se produjo una falla comunicándose con el servidor. Por favor intentar más tarde.',
    LinkModalDescriptionQBO: 'Después de hacer clic en \'Connect to QuickBooks\', Intuit le pedirá que ' +
        'autorizar una conexión a QuickBooks.',
    LinkModalDescriptionQBD: 'Después de hacer clic en \'Conectar a QuickBooks\', se le mostrarán instrucciones ' +
        'para instalar \'Strongbox Connect\' y compartir su información.Una vez que haya seguido estas instrucciones, ¡estará en camino!',
    LinkModalDescriptionXero: 'Después de hacer clic en \'Connect to Xero\', Xero le pedirá que autorice una conexión a Xero.',
    LinkModalDescriptionSageIntacct: 'Después de hacer clic en \'Connect to Sage Intacct\', Sage le pedirá que autorice una conexión a Sage Intacct.',
    LinkModalDescriptionExample: 'La información en DEX nunca será alterada de ninguna manera. Este es un flujo de datos unidireccional totalmente seguro.',
    LinkModalQBOneWay: 'La información en QuickBooks nunca se alterará de ninguna manera. Este es un flujo de datos unidireccional totalmente seguro.',
    LinkModalXeroOneWay: 'La información de Xero nunca se modificará de ninguna manera. Este es un flujo de datos unidireccional totalmente seguro.',
    LinkModalSageIntacctOneWay: 'La información de Sage Intacct nunca se modificará de ninguna manera. Este es un flujo de datos unidireccional totalmente seguro.',
    LinkModalExampleOneWay: 'La información del Sistema de contabilidad de ejemplo nunca se modificará de ninguna manera. Este es un flujo de datos unidireccional totalmente seguro.', 
    LinkModalWorking: 'Trabajando...',
    LinkModalUpdateFinancialsNow: 'Actualice las finanzas ahora',
    TermsDescriptionParagraph1PreSBX: '${partnerName} usos',
    TermsDescriptionParagraph1PostSBX: 'para vincular a su sistema de contabilidad',
    TermsDescriptionParagraph2: 'Al seleccionar \'Continuar\', acepta las',
    TermsDescriptionParagraph2Href: 'Strongbox Términos de servicio',
    TermsBullet1Title: 'Seguro',
    TermsBullet1Description: 'El cifrado protege los datos financieros de su empresa.',
    TermsBullet2Title: 'Privado',
    TermsBullet2Description: 'Sus credenciales nunca serán accesibles a ${partnerName}.',
    TermsBullet3Title: 'Conexión de solo lectura',
    TermsBullet3Description: 'No se alterará ninguna información en su sistema contable. Este es un flujo de datos unidireccional.',
    Continue: 'Seguir',
}
